<template>

	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>套餐管理</p>
			<i>/</i>
			<p @click="jumpPage">套餐列表</p>
			<i>/</i>
			<p class="active">套餐编辑</p>
		</nav>

		<div class="page_edit">

			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="name">
						<p class="form_label_p">套餐名称</p>
						<el-input class="input_long" v-model="ruleForm.name" maxlength="20" placeholder="请输入套餐名称(文本20)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="sort">
						<p class="form_label_p">排序</p>
						<el-input class="input_long" v-model="ruleForm.sort" placeholder="请输入排序(整数越小越靠前)">
						</el-input>
					</el-form-item>
					<div class="relation_goods">
						<p class="title">关联平台级产品</p>
						<div class="goods_option">
							<div class="goods_option_item" v-for="(item, s) in cities" :key="s">
								<div class="one_select_all">
									<el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
										@change="checked => handleCheckAllChange(checked, item)">{{item.cat_name}}</el-checkbox>
								</div>
								<div class="two_single_choice" v-if="item.products_list.length">
									<el-checkbox-group v-model="item.checkedCities" @change="checked => handleCheckedCitiesChange(checked, item)">
										<el-checkbox v-for="(city, index) in item.products_list" :label="city.id" :key="index">{{city.title}}
										</el-checkbox>
									</el-checkbox-group>
								</div>
							</div>
						</div>
					</div>
					<el-form-item label="" prop="">
						<p class="form_label_p">计算价格</p>
						<el-input class="input_long" disabled v-model="countPrice" placeholder="">
						</el-input>
						<span class="form_label_span">元/月</span>
					</el-form-item>
					<el-form-item label="" prop="price">
						<p class="form_label_p">销售价格</p>
						<el-input class="input_long" v-model="ruleForm.price" placeholder="请输入套餐销售价格">
						</el-input>
						<span class="form_label_span">元/月</span>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">状态</p>
						<el-radio-group v-model="ruleForm.status">
							<el-radio label="1">上架</el-radio>
							<el-radio label="0">下架</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>

		</div>
		
	</div>





</template>

<script>
	export default {
		name: 'packageEdit',
		data() {
			return {
				ruleForm: {
					name: '',
					price: '', // 套餐价
					sort: '',  // 排序
					status: '1', // 是否上架
				},
				rules: {
					name: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					price: [{
							required: true,
							message: '套餐价不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^[0-9]+(.[0-9]{1,2})?$/,
							message: '最多两位小数的数字'
						}
					],
					sort: [{
						pattern: /^\+?[1-9][0-9]*$/,
						message: '只能是非0的正整数',
					}]
				},
				cities: [],
				isIndeterminate: true,
				modifyBtn: true, // 默认创建
				chooseData: [],  //后台返回已选产品及数字
			}
		},
		computed:{
			countPrice(){
				let arr = [];
				let price = 0;
				this.cities.forEach( item => {
					if(item.checkedCities.length > 0){
						item.checkedCities.forEach( child => {
							item.products_list.forEach( list => {
								if(child == list.id){
									arr.push(list.price)
								}
							})
						})
					}
				})
				// 把字符串转换数字
				arr = arr.map(Number);
				arr.forEach( item => {
					price += item;
				})
				return price
			},
		},
		components: {},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				
				// 获取套餐做详情
				this.getPackageInfo()
			}
			else{
				// 获取套餐管理平台级产品
				this.getPlatformGoodsList();
			}
			
			
		},
		methods: {
			// 获取套餐做详情
			getPackageInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}combo/detail`,
					data: {
						id: this.$route.query.id,
					}
				}).then( res => {
					if(res.code == '0'){
						
						this.ruleForm.name = res.data.combo_name;
						this.ruleForm.price = res.data.price;
						this.ruleForm.status = res.data.status;
						this.ruleForm.sort = res.data.sort;
						
						this.chooseData = res.data.products_list;
						
						// 获取套餐管理平台级产品
						this.getPlatformGoodsList();
					}
				})
			},
			
			// 获取套餐管理平台级产品
			getPlatformGoodsList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}combo/productslist`,
					data: {
						rank: 1,
					}
				}).then( res => {
					if(res.code == '0'){
						this.cities = res.data.map( item => {
							this.$set(item, 'checkAll', false);
							this.$set(item, 'checkedCities', []);
							this.$set(item, 'isIndeterminate', true);
							return item;
						});
						
						if(this.chooseData.length > 0 && this.modifyBtn == false){
							this.chooseData.forEach( choose => {
								this.cities.forEach( (citie, i) => {
									if(citie.products_list.length > 0){
										citie.products_list.forEach( item => {
											if(item.id == choose){
												this.cities[i].checkedCities.push(item.id)
											}
										})
									}
								})
							})
						}
					}
				})
			},

			// 全选
			handleCheckAllChange(val, item) {
				let arr = [];
				item.products_list.forEach( s => {
					arr.push(s.id)
				})
				item.checkedCities = val ? arr : [];
				item.isIndeterminate = false;
			},

			// 单选
			handleCheckedCitiesChange(value, item) {
				let checkedCount = value.length;
				item.checkAll = checkedCount === item.products_list.length;
				item.isIndeterminate = checkedCount > 0 && checkedCount < item.products_list.length;
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						
						let arr = []
						this.cities.forEach( item => {
							if(item.checkedCities.length > 0){
								item.checkedCities.forEach( child => {
									arr.push(child)
								})
							}
						})
						
						if(arr.length == 0){
							this.$alert('请至少关联一个平台级产品', '关联产品缺失', {
								confirmButtonText: '确定',
								callback: action => {
									console.log(action)
								}
							});
							return;
						}
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}combo/add`,
							data: {
								combo_name: this.ruleForm.name,
								price: this.ruleForm.price,
								status: this.ruleForm.status,
								sort: this.ruleForm.sort,
								products_list: JSON.stringify(arr)
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						let arr = []
						this.cities.forEach( item => {
							if(item.checkedCities.length > 0){
								item.checkedCities.forEach( child => {
									arr.push(child)
								})
							}
						})
						
						if(arr.length == 0){
							this.$alert('请至少关联一个平台级产品', '关联产品缺失', {
								confirmButtonText: '确定',
								callback: action => {
									console.log(action)
								}
							});
							return;
						}
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}combo/edit`,
							data: {
								id: this.$route.query.id,
								combo_name: this.ruleForm.name,
								price: this.ruleForm.price,
								status: this.ruleForm.status,
								sort: this.ruleForm.sort,
								products_list: JSON.stringify(arr)
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}

	.relation_goods {
		padding: 24px;
		background-color: #fff;

		.title {
			font-size: 14px;
			line-height: 40px;
			color: rgba(0, 0, 0, .85);
		}

		.goods_option_item {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 8px;

			.one_select_all,
			.two_single_choice {
				padding: 12px;
				border: 1px solid rgba(0, 0, 0, 0.2);
			}

			.two_single_choice {
				border-left: none;
			}

		}
	}
</style>
